import React, { ReactChild, useState } from 'react'
import cx from 'classnames'
import Link from 'next/link'
import Undo from '@ant-design/icons/ArrowLeftOutlined'
import NumberFormat from 'react-number-format'
import {
  Button as AButton,
  Input as AInput,
  Col,
  Row,
  Spin as ASpin,
  Space,
  Checkbox,
  Alert,
} from 'antd'
import { SlideDown } from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'
import LoadingOutlined from '@ant-design/icons/LoadingOutlined'
import ArrowDown from '@ant-design/icons/ArrowDownOutlined'
import Arrow from '../svg/arrow-tr.svg'
import Close from '@ant-design/icons/CloseCircleTwoTone'
import SvgLogo from '../svg/logo.svg'
import HeaderWhiteLogo from '../svg/header-white-logo.svg'
import HeaderDarkLogo from '../svg/header-dark-logo.svg'
import FooterLogo from '../svg/footer-logo.svg'
import styles from '../styles/ui.module.sass'
import SvgAlert from '../svg/alert.svg'

export const HeadCover = ({ variant, ...props }: any) => {
  return (
    <div
      {...props}
      className={cx(styles.headCover, {
        [styles[variant]]: variant,
      })}
    ></div>
  )
}

export const HeadSection = ({ pic, ...props }: any) => {
  return (
    <div
      {...props}
      className={cx(styles.headSection, { [styles.pic]: pic })}
    ></div>
  )
}

export const TopBar = (props) => {
  return <div className={styles.topBar}>{props.children}</div>
}

export const Block = ({ typo, minHeight, className, ...props }: any) => {
  return (
    <div
      className={cx(styles.block, className, {
        [styles.typo]: typo,
        [styles.minHeight]: minHeight,
      })}
    >
      {props.children}
    </div>
  )
}

export const FormBlock = ({ msg, narrow, huge, message, ...props }: any) => {
  return (
    <div
      className={cx(styles.formBlock, {
        [styles.msg]: msg,
        [styles.huge]: huge,
        [styles.message]: message,
        [styles.narrow]: narrow,
      })}
    >
      {props.children}
    </div>
  )
}

export const Section = ({ className, dark, ...props }: any) => {
  return (
    <div
      {...props}
      className={cx(styles.section, className, { [styles.dark]: dark })}
    ></div>
  )
}

export const ImageHome = (props) => {
  return <div {...props} className={styles.imageHome}></div>
}

export const Input = ({ className, white, ...props }: any) => {
  return (
    <AInput
      className={cx(styles.input, {
        'ant-input-huge': props.size === 'huge',
        [styles.alignCenter]: props.align === 'center',
        'ant-white': white,
      })}
      {...props}
    />
  )
}

export const AmountInput = ({ size, white, ...props }: any) => {
  return (
    <NumberFormat
      thousandSeparator
      autoComplete="off"
      prefix={'$'}
      className={cx('ant-input', {
        'ant-input-lg': size === 'large',
        'ant-input-huge': size === 'huge',
        'ant-white': white,
        [styles.alignCenter]: props.align === 'center',
      })}
      {...props}
    />
  )
}

export const FormatNumber = ({ value, plain, suffix }: any) => {
  return (
    <NumberFormat
      thousandSeparator
      autoComplete="off"
      displayType="text"
      prefix={plain ? undefined : '$'}
      suffix={suffix ?? undefined}
      value={value}
      decimalScale={2}
    />
  )
}
export const Button = React.forwardRef(
  ({ className, rounded, active, wide, white, ...props }: any, ref) => {
    return (
      <AButton
        ref={ref}
        className={cx(
          styles.button,
          {
            [styles.rounded]: rounded,
            [styles.wide]: wide,
            [styles.active]: active,
            'ant-btn-huge': props.size === 'huge',
            'no-style': !props.type,
            'ant-white': white,
          },
          className,
        )}
        {...props}
      ></AButton>
    )
  },
)

export const PriButton = React.forwardRef(
  ({ className, rounded, active, wide, white, ...props }: any, ref) => {
    return (
      <AButton
        ref={ref}
        className={cx(
          styles.priButton,
          {
            [styles.rounded]: rounded,
            [styles.wide]: wide,
            [styles.active]: active,
            'ant-btn-huge': props.size === 'huge',
            'no-style': !props.type,
            'ant-white': white,
          },
          className,
        )}
        {...props}
      ></AButton>
    )
  },
)

export const Page = (props) => {
  return <div {...props}></div>
}

export const Q = (props) => {
  return <h2 className={styles.q} {...props}></h2>
}

export const Cap = (props) => {
  return <div {...props} className={styles.cap}></div>
}

export const FormSection = (props) => {
  return (
    <div className={styles.formSection}>
      <Row gutter={24}>
        <Col xs={24} md={16}>
          {props.children}
        </Col>
      </Row>
    </div>
  )
}
export const Helper = (props) => {
  return (
    <div className={cx(styles.helper, { [styles.sub]: props.sub })}>
      {props.children}
    </div>
  )
}

export const Card = ({ className, ...props }: any) => {
  return <div className={cx(styles.card)} {...props}></div>
}

export const Items = (props: any) => {
  return <div className={cx(styles.items)} {...props}></div>
}

export const Score = (props: any) => {
  return <div className={cx(styles.score)} {...props}></div>
}

export const GetStarted = (props: any) => {
  return <div className={cx(styles.getStarted)} {...props}></div>
}

let logo = <SvgLogo />

export const Logo = (props) => {
  if (props.clickable === false) {
    return logo
  }
  return (
    <Link href="/">
      <a className={styles.logo}>{logo}</a>
    </Link>
  )
}

export let delay = (t = 5) => new Promise((d) => setTimeout(d, t))

const antIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />

export let Spinner = ({ fullScreen, absolute, fixed, text }: any) => {
  return (
    <div
      className={cx(styles.spinner, {
        [styles.fullScreen]: fullScreen,
        [styles.absolute]: absolute,
        [styles.fixed]: fixed,
      })}
    >
      <span>
        <ASpin indicator={antIcon} />
        {text && <span className={styles.spinText}>{text}</span>}
      </span>
    </div>
  )
}

export let Spin = () => <ASpin indicator={antIcon} />

export let CloseButton = () => {
  return (
    <a className={styles.closeButton}>
      <Close />
    </a>
  )
}

export let Terms = () => {
  return (
    <Row justify="center" className={styles.terms}>
      <Space align="center" direction="vertical" size={20}>
        <SvgAlert className={styles.svgAlert} />

        <div>
          <p>
            Actions on ILOCX will directly affect your Director Check and
            Reputation scores. Preliminary due diligence and compliance checks
            have been attached to this application to be reviewed by ILOCX. All
            term sheets are proposals only based on the information provided at
            the time of the application. We reserve the right to withdraw the
            term sheet at any time. Our term sheet covers confidentiality for
            both parties. Please sign and return it.
          </p>

          <p>
            ILOCX will continually monitor progress on your web scores, director
            scores and other key pieces of data.
          </p>
        </div>
      </Space>
    </Row>
  )
}

export let AgreeSubmit = (props: any) => {
  let { formik } = props
  let { errors, touched, values } = formik
  return (
    <div className={styles.agree}>
      <Row gutter={[24, 16]}>
        <Col sm={12}>
          <Row justify="end" align="middle" style={{ minHeight: '100%' }}>
            <Col>
              <div>
                <Checkbox
                  name="agree"
                  checked={values.agree}
                  onChange={formik.handleChange}
                >
                  I agree to the Terms and Conditions
                </Checkbox>

                {formik.submitCount > 0 && errors.agree && (
                  <Alert type="warning" message={errors.agree} />
                )}
              </div>
            </Col>
          </Row>
        </Col>
        <Col>
          <PriButton
            wide
            htmlType="submit"
            loading={formik.isSubmitting}
            disabled={formik.isSubmitting}
            size="huge"
            type="primary"
          >
            Submit My Application
          </PriButton>
        </Col>
      </Row>

      <Terms />
    </div>
  )
}

export const scores = [
  'amount',
  'assumedAudience',
  'issuePrice',
  'licensesNumber',
  // 'totalSales',
  'royaltyPayment',
  'openingTradingPrice',
]

let labels = {
  amount: 'Raise',
  assumedAudience: 'Assumed Audience',
  issuePrice: 'Issue Price',
  licensesNumber: 'Buyers',
  // totalSales: 'Revenue',
  royaltyPayment: 'Royalty Payment',
  openingTradingPrice: 'Opening Trading Price',
}

export const Scores = (props) => {
  const values = {...props, scores: {...props.scores, assumedAudience: 5000, amount: Math.round(props.scores.amount / 100000) * 100000, licensesNumber: Math.round(props.scores.licensesNumber / 100000) * 100000 }}
  return (
    <Row>
      {scores.map((name) => {
        return (
          <Col key={name} xs={props.xs || 12} sm={props.sm || 8}>
            <Score>
              <label>{labels[name]}</label>
              <strong>
                <FormatNumber
                  value={values.scores[name]}
                  plain={name === 'licensesNumber' || name === 'assumedAudience' || name === 'buyers'}
                  suffix={(name === 'assumedAudience' || name === 'buyers') ? '+' : undefined}
                />
              </strong>
            </Score>
          </Col>
        )
      })}
    </Row>
  )
}

export const ButtonSelect = (props) => {
  return (
    <div className={styles.btnSelect}>
      {props.options.map((o) => {
        return (
          <Button
            size="large"
            type="ghost"
            active={props.value === o.value}
            key={o.value}
            onClick={() => props.onChange(o.value)}
            {...props.buttonProps}
          >
            {o.label}
          </Button>
        )
      })}
    </div>
  )
}

export const Year = ({ className, year, ...props }: any) => {
  return (
    <div
      {...props}
      className={cx(styles.year, className, {
        [styles.year2]: year === 2,
      })}
    ></div>
  )
}

export const YearImg = ({ className, ...props }: any) => {
  return <div {...props} className={cx(styles.yearImg, className)}></div>
}

export const Reasons = ({ className, ...props }: any) => {
  return <div {...props} className={cx(styles.reasons, className)}></div>
}

export const Reason = ({ className, ...props }: any) => {
  return (
    <div
      {...props}
      tabIndex={0}
      className={cx(styles.reason, className)}
      onFocus={(e) => {
        try {
          // @ts-ignore
          e.currentTarget.querySelector(`.read-more`).click()
        } catch (e) {}
      }}
    ></div>
  )
}

export const ReasonMore = (props) => {
  let [open, toggle] = useState(false)
  return (
    <div>
      <SlideDown className={'my-dropdown-slidedown'}>
        {open ? props.children : null}
      </SlideDown>

      <a className="read-more" onClick={() => toggle(!open)}>
        <span>
          Read more <ArrowDown />
        </span>
      </a>
    </div>
  )
}

export const Back = ({ to, children }) => {
  return (
    <Link href={to}>
      <Button className={styles.back} icon={<Undo />} type="link">
        {children}
      </Button>
    </Link>
  )
}

export const CompanyNumber = ({ className, info, ...props }: any) => {
  return (
    <a
      href={info.opencorporates_url}
      {...props}
      className={cx(styles.companyNumber, className)}
    >
      {info.company_number}
      <Arrow />
    </a>
  )
}

// Home component
export const Header = ({ white }: { white?: boolean }) => {
  return (
    <div className={cx(styles.header, { [styles.whiteColor]: white })}>
      <Section>
        <div className={cx(styles.block, styles.headerWrapper)}>
          <div className={styles.logoWrapper}>
            {white ? <HeaderWhiteLogo /> : <HeaderDarkLogo />}
            <div className={styles.headerQuote}>QUOTE</div>
          </div>
          <div className={styles.headerMenuWrapper}>
            <Link href="/onboarding">Get a Quote</Link>
            <Link href="/listing-agent?name=Alfie">Listing Agent</Link>
            <Link href="#">ILOCX</Link>
          </div>
        </div>
      </Section>
    </div>
  )
}

export const Footer = () => {
  return (
    <div className={styles.footer}>
      <Section>
        <div className={cx(styles.block, styles.footerWrapper)}>
          <div className={styles.logoWrapper}>
            <FooterLogo />
            <div className={styles.footerQuote}>QUOTE</div>
          </div>
          <div className={styles.footerMenuWrapper}>
            <Link href="/faq">FAQ</Link>
            <Link href="/policy">Privacy Policy</Link>
            <Link href="#">Buyer Terms</Link>
          </div>
        </div>
      </Section>
    </div>
  )
}

interface PrimaryButtonProps {
  children: ReactChild
  onClick?: () => void
  disabled?: boolean
  type?: "button" | "submit"
}

export const PrimaryLightButton = ({
  children,
  onClick,
  type
}: PrimaryButtonProps) => (
  <button className={styles.primaryLightButton} onClick={onClick} type={type?? "button"}>
    {children}
  </button>
)

export const PrimaryButton = ({
  children,
  disabled,
  onClick,
}: PrimaryButtonProps) => (
  <button
    className={cx(styles.primaryButton, { [styles.disabled]: disabled })}
    disabled={disabled ?? false}
  >
    {children}
  </button>
)

export const SpinnerLoading = () => (
  <ASpin
    indicator={<LoadingOutlined style={{ fontSize: 35 }} spin />}
    className={styles.spinnerLoading}
  />
)

interface ThumbProps {
  active?: boolean
  onClick: () => void
}

export const ThumbUp = ({ active, onClick }: ThumbProps) => {
  return (
    <button
      className={cx(styles.thumbWrapper, styles.thumbUp, {
        [styles.active]: active ? true : false,
      })}
      onClick={onClick}
    >
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.5 8.125H6.25V16.25H2.5C2.33424 16.25 2.17527 16.1842 2.05806 16.0669C1.94085 15.9497 1.875 15.7908 1.875 15.625V8.75C1.875 8.58424 1.94085 8.42527 2.05806 8.30806C2.17527 8.19085 2.33424 8.125 2.5 8.125V8.125Z"
          stroke="#38A169"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.25 8.125L9.375 1.875C10.038 1.875 10.6739 2.13839 11.1428 2.60723C11.6116 3.07607 11.875 3.71196 11.875 4.375V6.25H16.7109C16.8882 6.24956 17.0635 6.28706 17.225 6.35997C17.3866 6.43288 17.5306 6.53953 17.6476 6.67273C17.7645 6.80593 17.8516 6.96262 17.9029 7.13226C17.9543 7.3019 17.9687 7.48056 17.9453 7.65625L17.0078 15.1562C16.9699 15.4573 16.8237 15.7343 16.5966 15.9356C16.3695 16.1368 16.0769 16.2486 15.7734 16.25H6.25"
          stroke="#38A169"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </button>
  )
}

export const ThumbDown = ({ active, onClick }: ThumbProps) => {
  return (
    <button
      className={cx(styles.thumbWrapper, styles.thumbDown, {
        [styles.active]: active ? true : false,
      })}
      onClick={onClick}
    >
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.5 3.75H6.25V11.875H2.5C2.33424 11.875 2.17527 11.8092 2.05806 11.6919C1.94085 11.5747 1.875 11.4158 1.875 11.25V4.375C1.875 4.20924 1.94085 4.05027 2.05806 3.93306C2.17527 3.81585 2.33424 3.75 2.5 3.75V3.75Z"
          stroke="#E53E3E"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.25 11.875L9.375 18.125C10.038 18.125 10.6739 17.8616 11.1428 17.3928C11.6116 16.9239 11.875 16.288 11.875 15.625V13.75H16.7109C16.8882 13.7504 17.0635 13.7129 17.225 13.64C17.3866 13.5671 17.5306 13.4605 17.6476 13.3273C17.7645 13.1941 17.8516 13.0374 17.9029 12.8677C17.9543 12.6981 17.9687 12.5194 17.9453 12.3438L17.0078 4.84375C16.9699 4.54267 16.8237 4.26567 16.5966 4.06442C16.3695 3.86317 16.0769 3.75141 15.7734 3.75H6.25"
          stroke="#E53E3E"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </button>
  )
}

export const ProgressBar = ({ percent }: { percent: number }) => {
  return (
    <div>
      <div className={styles.barWrapper}>
        <div
          className={styles.satisfied}
          style={{ width: (percent * 100) + '%' }}
        ></div>
      </div>
      <p className={styles.barContent}>{Math.round(percent * 100) }% people say they’re happy with this company 👍</p>
    </div>
  )
}
